import * as CONSTANTS from '../constants';
import axios from 'axios';

const url = `${CONSTANTS.URL}/customer`;;

const fetchFirebaseData = async () => {
    return axios.get(url, {
        headers: { 'Authorization': `Bearer ${sessionStorage.getItem('token-embuber')}` }
    })
}

export const insertClient = async (name, phone, email, address) => {
    let body = {
        name: name,
        phone: phone,
        email: email,
        address: address,
    }

    return axios.post(`${url}`, body, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionStorage.getItem('token-embuber')}`
        }
    })
}

export const getClientById = async (id) => {
    return axios.get(`${url}/${id}`, {
        headers: { 'Authorization': `Bearer ${sessionStorage.getItem('token-embuber')}` }
    })
}

export const updateClient = async (id, nome, telefone, email, endereco) => {
    let body = {
        customerId: id,
        name: nome,
        phone: telefone,
        email: email,
        address: endereco
    }

    return axios.put(`${url}/${id}`, body, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionStorage.getItem('token-embuber')}`
        }
    })
}

export const deleteClient = async (id) => {
    return axios.delete(`${url}/${id}`, {
        headers: { 'Authorization': `Bearer ${sessionStorage.getItem('token-embuber')}` }
    })
}

export default fetchFirebaseData;