import React, { useEffect, useState } from "react";
import { getProductsOnLocalStorage, sendBudgetRequestEmail } from '../service/budget-service';

import { useHistory } from "react-router-dom";

import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import CartRow from '../components/cart-row';
import BudgetForm from '../components/budget-form';


const Card = (props) => {
    const [cardItems, setCardItems] = useState([])
    const [show, setShow] = useState(false);
    const [contactForm, setContactForm] = useState({})

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleFinalizeBudget = () => {
        console.log("contact form", contactForm)
        sendBudgetRequestEmail(contactForm);
        handleClose();
    }

    useEffect(() => setCardItems(getProductsOnLocalStorage()), [])
    
    const updateRowsOnDeleteItem = () => {
        setCardItems([]);
        setTimeout(() => {
            setCardItems(getProductsOnLocalStorage());
        }, 500);
    }

    const updateCartItemQuantity = () => {
        props.updateCartItemNumbers();
    }

    let history = useHistory();

    const goToPreviousPath = () => { history.goBack() }

    const budgetFormData = (form) => { setContactForm(form) }

    return (
        <>
            <div className="">
                <Table responsive="sm">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Produto</th>
                            <th>Capacidade</th>
                            <th>Qauntidade</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {cardItems.map((item, i) => {
                            return (
                                <>
                                    {<CartRow
                                        updateRowsOnCart={() => updateRowsOnDeleteItem()}
                                        item={item}
                                        i={i}
                                        updateCartItemQuantity={() => updateCartItemQuantity()} />}
                                </>
                            )
                        })}
                    </tbody>
                </Table>

                <div className="card-butons">
                    <div>
                        <Button onClick={goToPreviousPath} variant="secondary">Voltar</Button>
                    </div>
                    <div>
                        <Button variant="info" onClick={handleShow}>
                            Finalizar Orçamento
                        </Button>
                    </div>
                </div>
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Modal heading</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <BudgetForm getFormData={(form) => budgetFormData(form)} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Cancelar
                                </Button>
                        <Button variant="info" onClick={handleFinalizeBudget}>
                            Finalizar Orçamento
                                </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    )
}

export default Card;