import * as CONSTANTS from '../pages/admin/constants';
import axios from 'axios';


const url = `${CONSTANTS.URL}/site/Category`;

const CategoryService = async => {
    return axios.get(url)
}

export default CategoryService;