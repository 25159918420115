import React, { useState } from "react";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Box, LinearProgress } from '@material-ui/core';


import LoginService from '../../../../service/login-service';


const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
            display: 'flex',
            'flex-direction': 'collumn'
        },
    },
}));


function LoginForm(props) {
    const classes = useStyles();
    const [userName, setUserName] = React.useState('');
    const [pass, setPass] = React.useState('');
    const [loginError, setLoginError] = useState('');
    const [progress, setProgress] = React.useState(0);
    const [isLoading, setIsLoading] = React.useState(false);

    React.useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    return 0;
                }
                const diff = Math.random() * 10;
                return Math.min(oldProgress + diff, 100);
            });
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, []);


    const handleSignUp = async (userName, pass, event) => {
        event.preventDefault();
        setIsLoading(true);
        await LoginService(userName, pass)
            .then(response => {
                props.getAuth(response.data.token);
                if (!response.data.isValid) {
                    setIsLoading(false);
                    setLoginError(response.data.message)
                }
            }).catch((err) => console.log(err))
    };

    return (
        <div id="login-page">
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
            >
                <div id="login-form">
                    <div class="logo-container" >
                        <img class="logo-image" src="embalagens-uberlandia-logo.svg" />
                    </div>
                    <form className={classes.root} noValidate autoComplete="off" onSubmit={(event) => handleSignUp(userName, pass, event)}>
                        <div>
                            <TextField
                                id="outlined-multiline-flexibles"
                                label="Login"
                                multiline
                                rowsMax="4"
                                name="setEmail"
                                value={userName}
                                onChange={e => setUserName(e.target.value)}
                                variant="outlined"
                            />
                            <TextField
                                id="standard-password-input"
                                label="Senha"
                                type="password"
                                autoComplete="current-password"
                                value={pass}
                                onChange={e => setPass(e.target.value)}
                                variant="outlined"
                            />
                        </div>

                        <div className="button-login">
                            <Button type="submit" variant="contained" color="primary">
                                Login
                            </Button>
                        </div>
                        <br />
                        {isLoading &&
                            <Box sx={{ width: '100%' }}>
                                <LinearProgress variant="determinate" value={progress} />
                            </Box>
                        }
                    </form>
                    {loginError ? <div className="error-message-login">{loginError}</div> : null}
                </div>
            </Grid>
        </div>
    );
}

export default LoginForm;