import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ModalImage from "react-image-modal";

import React from "react";
import { Link } from "react-router-dom";

import loading from '../../helper/loading';

import CategoryService from "../../service/category-service";

import './category.css';

const Category = (props) => {
    const [cat, setCat] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);

    // useEffect(async () => {
    //     await CategoryService()
    //         .then(x => {
    //             setCat(x.data);
    //             setIsLoading(false);
    //         });
    // }, [])

    const setCategory = (catId) => props.changeCategory(catId);


    const getCategorytImage = (photo) => {
        return photo
            ? photo
            : '/images/no-image-portrait.jpeg'
    }


    const openImageModal = () => {

        alert("wS")
    }

    return (
        <div className='body-images'>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(e => {
                return (
                    <div className='image-div'>
                        <div className='parent-image'>

                            <ModalImage
                                // showDownload={true}
                                showRotation={false}
                                showZoom={true}
                                src={`../images/catalogo/catalogo_${e}.png`}
                                alt="Potes"
                                className="modalImage"
                            />
                        </div>
                        {/* <img src={`../images/catalogo/catalogo_${e}.png`} onClick={() => openImageModal()} /> */}
                    </div>
                )
            })}





        </div>
    )
}

export default Category;