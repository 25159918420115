import React, { useEffect } from 'react';

import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Modal from '@material-ui/core/Modal';
import EditIcon from '@material-ui/icons/Edit';
import { Button } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';


import NewOrUpdate from './NewOrUpdate';

import Date from '../Helper/formatDate'
import { insertSale } from './service'

const columns = [
  { id: 'edit', label: '', minWidth: 20, width: 20 },
  { id: 'produto', label: 'Produto', minWidth: 200, width: 250 },
  // { id: 'capacidade', label: 'Capacidade', minWidth: 200, width: 250 },
  {
    id: 'quantidade',
    label: 'Quantidade',
    minWidth: 190,
    align: 'right',
    width: 190
  },
  {
    id: 'preco-compra',
    label: 'Compra',
    minWidth: 190,
    align: 'right',
    width: 190
  },
  {
    id: 'preco-venda',
    label: 'Venda',
    minWidth: 190,
    align: 'right',
    width: 190
  },
  {
    id: 'produto-total',
    label: 'Total',
    minWidth: 190,
    align: 'right',
    width: 190
  }
];

function getModalStyle() {
  const top = 50;
  const left = 25;

  return {
    top: `${top}px`,
    left: `${left}%`,
    width: '50%'
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 900,
  },
  align: {
    textAlign: 'left'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    position: 'relative'
  },
  greenBtn: {
    backgroundColor: '#099909'
  },
  actionbuttons: {
    display: 'flex'
  },
  padding: {
    padding: '14px'
  },
  flexSpaceBetween: {
    display: 'flex',
    justifyContent: 'space-arround'
  },
  fontSizeRem: {
    fontSize: '1.6rem',
    padding: '14px 10px'
  },
  totalSale: {
    fontSize: '1.6rem',
    padding: '14px 0px'
  },
  priceClient: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '73%'
  }
}));

const Sale = (props) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [modalStyle] = React.useState(getModalStyle);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [disableSale, setDisableSale] = React.useState(true);
  const [customerName, setCustomerName] = React.useState('');
  const [idToEdit, setIdToEdit] = React.useState(0);
  const [itemToEdit, setItemToEdit] = React.useState({});
  const [isEdit, setIsEdit] = React.useState(false);


  const handleOpen = () => {
    console.log(Date())
    setOpen(true);
  };

  useEffect(() => {
    if (rows.length > 0) {
      setDisableSale(false)
    } else {
      setDisableSale(true)
    }
  }, [rows])

  const handleClose = () => {
    setOpen(false);
    setIsEdit(false);
  };

  const handleDoneSale = async (items) => {
    await insertSale(customerName, items, calculateTotalSale())
  }

  const handleMouseOver = () => {
    document.getElementById('doSale').style.backgroundColor = 'green';
  }

  const handleMouseLeave = () => {
    document.getElementById('doSale').style.backgroundColor = '#099909';
  }

  function getIdToEdit(id, row) {
    setIdToEdit(id);
    setItemToEdit(row);
    setIsEdit(true);
    handleOpen()
  }

  function calculateTotalProduct(price, quantity) {
    return (Number(price) * Number(quantity)).toFixed(2)
  }

  function calculateTotalSale() {
    if (rows.length > 0) {
      let totalProducts = rows.map(row => {
        return (Number(row.precoVenda) * Number(row.quantidade))
      })

      let totalSale = totalProducts.reduce((prev, curr) => {
        return prev + curr
      })

      return totalSale.toFixed(2)
    }
  }

  return (
    <Paper className={classes.root}>
      <div className={classes.flexSpaceBetween}>
        <div className={classes.actionbuttons}>
          <div onClick={() => handleOpen()} className={classes.padding}>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              endIcon={<AddIcon />}
            >
              ADICIONAR PRODUTO
        </Button>

          </div>
          <div className={classes.padding}>
            <Button
              id="doSale"
              variant="contained"
              color="primary"
              className={classes.greenBtn}
              endIcon={<MonetizationOnIcon />}
              onClick={() => handleDoneSale(rows)}
              onMouseOver={() => handleMouseOver()}
              onMouseLeave={() => handleMouseLeave()}
              disabled={disableSale}
            >
              CONCLUIR VENDA
        </Button>
          </div>
        </div>

        <div className={classes.priceClient}>
          <div className={classes.totalSale}>
            {'R$ ' + (calculateTotalSale() == undefined ? 0.00 : calculateTotalSale())}
          </div>
          <div className={classes.fontSizeRem}>
            {/* {customerName} */}
          </div>
        </div>

      </div>
      <TableContainer className={classes.container}>

        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  className={classes.align}
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, width: column.width }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows && rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} >
                  <TableCell onClick={() => getIdToEdit(row.id, row)} className={classes.align} style={{ width: columns[0].width }}>
                    <EditIcon />
                  </TableCell>
                  <TableCell className={classes.align}>
                    {row.produto + " " + row.capacidade}
                  </TableCell>
                  <TableCell className={classes.align}>
                    {row.quantidade}
                  </TableCell>
                  <TableCell className={classes.align}>
                    {row.precoCompra}
                  </TableCell>
                  <TableCell className={classes.align}>
                    {row.precoVenda}
                  </TableCell>
                  <TableCell className={classes.align}>
                    {calculateTotalProduct(row.precoVenda, row.quantidade)}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
      />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <NewOrUpdate
            callProdutcsInsertOnSale={(venda) => setRows([...rows, venda])}
            closeModal={() => handleClose()}
            productId={idToEdit}
            getCustomerOnSale={(customer) => setCustomerName(customer)}
            callEditProductOnSale={(upToDateRoex) => setRows(upToDateRoex)}
            rows={rows}
            isEdit={isEdit}
            item={itemToEdit}
            productsList={rows}
            cleanProductId={() => setIdToEdit(0)}
          />
        </div>
      </Modal>

    </Paper>
  );
}
export default Sale;