import React from 'react';
import AllProducts from './AllProducts';

const Products = () => {
  return (
    <div>
      <AllProducts />
    </div>
  )
};
export default Products;