import React, { useEffect } from 'react';

import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

import { insertClient, getClientById, updateClient, deleteClient } from './service'


const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    flex: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    btnStyle: {
        '& .MuiButton-label': {
            width: '50px',
        },
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '20px'
    },
    btnSpace: {
        marginRight: '10px'
    }
}));


const NewOrUpdate = (props) => {
    const classes = useStyles();
    const [nome, setNome] = React.useState('');
    const [telefone, setTelefone] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [endereco, setEndereco] = React.useState('');

    useEffect(() => {
        if (props.clientId) {
            fillInputs()
        }

    }, [])

    function fillInputs() {
        getClientById(props.clientId).then(res => {
            setNome(res.data.name)
            setTelefone(res.data.phone)
            setEmail(res.data.email)
            setEndereco(res.data.address)
        })
    }

    function isertOrUpdateUser() {
        if (props.clientId) {
            updateClient(props.clientId, nome, telefone, email, endereco)
                .then(() => {
                    props.callUserUpdateOrChanged();
                    props.closeModal();
                })


        } else {
            insertClient(nome, telefone, email, endereco)
                .then(() => {
                    props.callUserUpdateOrChanged();
                    props.closeModal();
                })
        }
    }

    function removeCliente(id) {
        deleteClient(id).then(() => {
            props.callUserUpdateOrChanged();
            props.closeModal();
        })
    }

    return (
        <div className={classes.flex}>

            <form className={classes.root} noValidate autoComplete="off">
                <div>
                    <TextField
                        required
                        id="cliente-nome"
                        label="Nome"
                        variant="outlined"
                        value={nome}
                        onChange={(e) => setNome(e.target.value)}
                    />
                    <TextField
                        required
                        id="cliente-telefone"
                        label="Telefone"
                        value={telefone}
                        variant="outlined"
                        type="number"
                        onChange={(e) => setTelefone(e.target.value)}
                    />
                    <TextField
                        required
                        id="cliente-email"
                        label="Email"
                        value={email}
                        variant="outlined"
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                        required
                        id="cliente-endereco"
                        label="Endereço"
                        value={endereco}
                        variant="outlined"
                        onChange={(e) => setEndereco(e.target.value)}
                    />
                </div>

            </form>

            <div className={classes.btnStyle}>
                {props.clientId != 0 ?
                    <div className={classes.btnSpace}>
                        <Button onClick={() => removeCliente(props.clientId)} variant="contained" color="secondary">
                            Delete
                </Button>
                    </div>
                    : null}
                <Button onClick={() => isertOrUpdateUser()} variant="contained" color="primary">
                    Salvar
                </Button>
            </div>


        </div>
    );
}
export default NewOrUpdate;