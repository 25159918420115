import React, { useEffect } from 'react';

import AllClients from './AllClients';

const Clients = () => {

    return (
        <div>
          
            <AllClients />
        </div>
    );
}
export default Clients;