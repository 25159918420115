import React, { useEffect } from 'react';

import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

import fetchFirebaseProducteData, {
    insertProduct, getProductById, updateProduct, deleteProduct,
} from '../produtcs/service' //products to populate sale

import clientFetchFirebaseData from '../Clients/service'

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    flex: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    btnStyle: {
        '& .MuiButton-label': {
            width: '50px',
        },
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '20px'
    },
    btnSpace: {
        marginRight: '10px'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    }
}));

const NewOrUpdate = (props) => {
    const classes = useStyles();
    const [produtos, setProdutos] = React.useState([]);
    const [customers, setCustomers] = React.useState([]);
    const [customerName, setCustomerName] = React.useState('');
    const [produtoNome, setprodutoNome] = React.useState('');
    const [selectedProductId, setselectedProductId] = React.useState('');
    const [capacidade, setCapacidade] = React.useState('');
    const [quantidade, setQuantidade] = React.useState('');
    const [compra, setCompra] = React.useState('');
    const [venda, setVenda] = React.useState('');
    const [blockProdAndCustomer, setBlockProdAndCustomer] = React.useState(false);
    const [quantidadeSnapShot, setQuantidadeSnapShot] = React.useState('');
    const [quantidadeDisponivel, setquantidadeDisponivel] = React.useState(quantidadeSnapShot);
    const [isEdit, setIsEdit] = React.useState(props.isEdit);
    const [productsList, setProductsList] = React.useState([]);
    const [showCustomerDropDown, setShowCustomerDropDown] = React.useState(true);

    const handleChange = (event) => {
        setprodutoNome(event.target.value)
        
        let selectedProduct = function () {
            return produtos.filter(p => {
                return p.id == event.target.value
            })
        }
        setselectedProductId(selectedProduct()[0].id);
    };

    const handleChangeCustomer = (event) => {
        setCustomerName(event.target.value)
    }

    useEffect(() => {
        if (selectedProductId) {
            fillInputs(selectedProductId)
        }

        TurnOffCustomerDropDown()

    }, [selectedProductId, props.productsList])

    useEffect(() => { setProductsList(props.productsList) }, [props.productsList])

    useEffect(() => { setIsEdit(props.isEdit) }, [props.isEdit])

    useEffect(() => {
        getProducts();
        getCustomers();
        setBlockProdAndCustomer(isEdit)
        if (props.productId && isEdit) {
            fillInputs(props.productId)
        } else {
            setBlockProdAndCustomer(isEdit)
        }

    }, [])

    function fillInputs(productId) {
        getProductById(productId).then(res => {
            setprodutoNome(res.data.name)
            setCapacidade(res.data.capacity)
            setQuantidadeSnapShot(res.data.quantity)
            setCompra(res.data.costPrice)
            setVenda(res.data.sellPrice)
        })
    }

    async function getProducts() {
        await fetchFirebaseProducteData().then(cli => {
            setProdutos(getNonSelectedProducts(cli.data))
        })
    }

    function getNonSelectedProducts(productsList) {
        let arr1 = productsList;
        let arr2 = props.productsList;
        let arr3 = productsList
        for (let it2 in props.productsList) {
            for (let it1 in productsList) {
                if (JSON.stringify(arr2[it2].produto) == JSON.stringify(arr1[it1].produto)) {
                    arr3.splice(it1, 1);
                    break; // se achou, não precisa continuar o loop
                }
            }
        }
        return arr3;
    }

    async function getCustomers() {
        await clientFetchFirebaseData().then(cli => {
            setCustomers(cli.data)
        })
    }

    function editOrDelete() {
        props.rows.filter(row => {
            return ""
        })
        props.callEditProductOnSale()
    }

    const isProductInProductsList = () => {
        var x = props.productsList.map((e) => {
            return e.id;
        }).indexOf(props.productId);
        return x
    }

    const updateProductOnProductsList = () => {
        let seletedProduct = props.productsList.filter(p => {
            return p.id == props.productId
        })
        return seletedProduct[0];
    }

    const listWithoutSelected = () => {
        let seletedProduct = props.productsList.filter(p => {
            return p.id !== props.productId
        })

        if (seletedProduct.length > 0) {
            return seletedProduct;
        } else {
            return false;
        }
    }

    function TurnOffCustomerDropDown() {
        if (props.productsList.length > 0) {
            setShowCustomerDropDown(false)
        }
    }

    function isertOrUpdateUser() {
        if (isProductInProductsList() != -1) {
            let quantityUptoDate = updateProductOnProductsList()
            let productsArray = [];

            quantityUptoDate.quantidade = quantidade;
            quantityUptoDate.precoVenda = venda;

            if (listWithoutSelected()) {
                listWithoutSelected().map(el => {
                    productsArray.push(el)
                })
            }

            productsArray.push(quantityUptoDate)

            props.callEditProductOnSale(productsArray)
        } else {

            let addProductToSale = {
                id: selectedProductId,
                produto: produtoNome,
                capacidade: capacidade,
                precoVenda: venda,
                precoTabela: venda,
                precoCompra: compra,
                quantidade: quantidade,
            }

            if (props.productId != 0) {
                setIsEdit(false);
            }

            props.callProdutcsInsertOnSale(addProductToSale)

            if (showCustomerDropDown) {
                props.getCustomerOnSale(customerName)
            }
        }
        props.cleanProductId()
        props.closeModal()
    }

    function removeProduct(id) {

        let rowsReamin = props.rows.filter(r => {
            return r.id != id
        })
        props.callEditProductOnSale(rowsReamin)
        console.log(id)
        // deleteProduct(id).then(() => {
        //     props.callProdutcsUpdateOrChanged();
        //     props.closeModal();
        // })
    }

    const handleQuantityChange = (value) => {
        setQuantidade(value);
        setquantidadeDisponivel(quantidadeSnapShot - value)
    }

    return (
        <div className={classes.flex}>
            <form className={classes.root} noValidate autoComplete="off">
                <div>
                    {showCustomerDropDown &&
                        <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel htmlFor="outlined-age-native-simple">Cliente</InputLabel>
                            <Select
                                native
                                value={customerName}
                                onChange={(e) => handleChangeCustomer(e)}
                                label="Cliente"
                                disabled={blockProdAndCustomer}
                            >
                                <option aria-label="None" value="" />
                                {customers.map(customer => {
                                    return (<option value={customer.customerId}>{customer.name}</option>)
                                })}
                            </Select>
                        </FormControl>
                    }
                    <br />
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel htmlFor="outlined-age-native-simple">Produto</InputLabel>
                        <Select
                            native
                            value={produtoNome}
                            onChange={(e) => handleChange(e)}
                            label="Produto"
                            disabled={blockProdAndCustomer}
                        >
                            <option aria-label="None" value="" />
                            {produtos.map(product => {
                                return (<option value={product.id}>{product.name} - {product.capacity}</option>)
                            })}
                        </Select>
                    </FormControl>
                    <br />
                    <TextField
                        required
                        id="cliente-telefone"
                        label="Quantidade"
                        value={quantidade}
                        variant="outlined"
                        type="number"
                        onChange={(e) => handleQuantityChange(e.target.value)}
                    />
                    {quantidadeDisponivel && `(${quantidadeDisponivel})`}
                    <TextField
                        required
                        id="cliente-email"
                        label="Capacidade"
                        value={capacidade}
                        variant="outlined"
                        onChange={(e) => setCapacidade(e.target.value)}
                    />
                    {!isEdit && <TextField
                        required
                        id="cliente-endereco"
                        label="Compra"
                        value={compra}
                        variant="outlined"
                        onChange={(e) => setCompra(e.target.value)}
                    />}
                    <TextField
                        required
                        id="cliente-endereco"
                        label="Venda"
                        value={venda}
                        variant="outlined"
                        onChange={(e) => setVenda(e.target.value)}
                    />
                </div>
            </form>

            <div className={classes.btnStyle}>
                {isEdit ?
                    <div className={classes.btnSpace}>
                        <Button onClick={() => removeProduct(props.productId)} variant="contained" color="secondary">
                            Delete
                </Button>
                    </div>
                    : null}
                <Button onClick={() => isertOrUpdateUser()} variant="contained" color="primary">
                    Salvar
                </Button>
            </div>
        </div>
    );
}
export default NewOrUpdate;