import React, { useEffect, useRef, useState } from "react";
import LoginForm from './pages/login/login-form';
import { isExpired } from "react-jwt";
import { MiniDrawer } from '../admin/navigation';

import { Redirect, useHistory } from "react-router-dom";

function Admin(props) {
    let history = useHistory();
    const [auth, setAuth] = useState(sessionStorage.getItem('token-embuber'))

    props.setCurrentPath("/admin");

    const getAuth = (token) => {
        setAuth(token);
        sessionStorage.setItem('token-embuber', token)
    }

    useEffect(() => {
        if (sessionStorage.getItem('token-embuber'))
            var isValidToken = isExpired(sessionStorage.getItem('token-embuber'));

        if (isValidToken) {
            sessionStorage.clear()
            setAuth(false);
        }
    }, [history.location.pathname])


    return !true ? (<Redirect push to="/admin" />
    ) : (
        <>
            {!auth ?
                <div id="login-page">
                    <LoginForm getAuth={(token) => getAuth(token)} />
                </div>
                :
                <MiniDrawer path={history.location.pathname} />
            }
        </>
    );
}

export default Admin;