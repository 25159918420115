import Jumbotron from 'react-bootstrap/Jumbotron';
import Container from 'react-bootstrap/Container'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'

import { Icon } from '@iconify/react';

import './footer.css';

const Footer = () => {
    return (
        <div className='footer-section'>
            <div className='footer'>
                <footer className='footer-container'>
                    <div className='endereco'>
                        <a href='https://www.google.com/maps/place/Embalagens+Uberl%C3%A2ndia/@-18.914508,-48.278113,15z/data=!4m5!3m4!1s0x0:0xd0e13bef358963ff!8m2!3d-18.914508!4d-48.278113'>
                            <img className='google-maps' src="google-maps.png" />
                        </a>
                        <span>R. Cel. Antônio Alves Pereira, 35
                            <br />Centro, Uberlândia - MG
                            <br />38400-104, Brasil
                        </span>

                    </div>

                    <div className='redes-sociais-container'>
                        <div className='redes-sociais'>
                            <span>Siga-nos nas nossas redes sociais</span>
                            <div className='social-icons'>
                                <a href="https://www.facebook.com/embalagensuberlandia">
                                    <img className='facebook-icon' src="facebook.png" />
                                </a>
                                <a href="https://www.instagram.com/embalagensuberlandia">
                                    <img className='instagram-icon' src="instagram.png" />
                                </a>
                            </div>

                        </div>
                    </div>

                    <div className='contatos'>

                        <div className='telefone'>
                            <Icon className='telefone-icon' icon="akar-icons:phone" />
                            <span>  (34) 3214-4232</span>
                        </div>

                        <div className='email'>
                            <Icon className='email-icon' icon="eva:email-outline" />
                            <span>comercial@embalagensuberlandia.com.br</span>
                        </div>


                        <div className='whatsapp'>
                            <Icon className='whatsapp-icon' icon="simple-icons:whatsapp" />
                            <span>(34) 98700-4973</span>
                        </div>
                    </div>

                </footer>
            </div>
            <div className='desenvolvedor-section'>
                Copyright © Embalagens Uberlândia - Todos os Direitos Reservados | Powered by JMac Technology
            </div>
        </div>
    )
}

export default Footer;