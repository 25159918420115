import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

// import fetchFirebaseData from '../Clients/service';
import fetchFirebaseProducteData from './produtcs/service';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    container: {
        padding: '10px'
    },
    align: {
        textAlign: 'left'
    },
}));

const SearchInput = (props) => {
    const classes = useStyles();
    const [search, setSearch] = useState('');
    const [page, setPage] = useState('');

    useEffect(() => {
        getDataAndFilter()
        setPage(props.page)
    }, [search])

    const getDataAndFilter = () => {
        switch (page) {
            case 'clients':
                // filterClients();
                break;
            case 'products':
                filterProducts();
                break;
            default:
                console.log('default')
        }
    }

    // const filterClients = async () => {
    //     var clientsArr = [];
    //     await fetchFirebaseData().then(cli => {
    //         cli.docs.map(doc => {
    //             const data = doc.data();
    //             data.id = doc.id;
    //             clientsArr.push(data)
    //         })
    //     })
    //     let filteredArray = clientsArr.filter((client => {
    //         return (client.nome.toLowerCase().indexOf(search.toLowerCase()) !== -1) ||
    //             (client.email.toLowerCase().indexOf(search.toLowerCase()) !== -1) ||
    //             (client.endereco.toLowerCase().indexOf(search.toLowerCase()) !== -1)
    //     })
    //     )
    //     props.filterClients(filteredArray)
    // }

    const filterProducts = async () => {

        var productsArr = [];
        
        await fetchFirebaseProducteData().then(p => {
            productsArr.push(p.data)
        })

        let filteredArray = productsArr[0].filter((prod => {
            return (prod.name.toLowerCase().indexOf(search.toLowerCase()) !== -1) ||
                (prod.capacity.toLowerCase().indexOf(search.toLowerCase()) !== -1)
        }))

        let filteredByName = filteredArray.sort((a, b) => {
            if (a.name < b.name) { return -1; }
            if (a.name > b.name) { return 1; }
            return 0;
        })

        let filteredByCapacity = filteredByName.sort((a, b) => {
            if (Number(a.capacity.replace(".", "").split(" ")[0]) < Number(b.capacity.replace(".", "").split(" ")[0])) { return -1; }
            if (Number(a.capacity.replace(".", "").split(" ")[0]) > Number(b.capacity.replace(".", "").split(" ")[0])) { return 1; }
            return 0;
        })

        props.filterClients(filteredByCapacity)
    }

    return (
        <Paper className={classes.root}>
            <div className={classes.container}>
                <TextField
                    id="outlined-helperText"
                    label="Pesquisar"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                />
            </div>
        </Paper >
    );
}

export default SearchInput;