import React, { useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Modal from '@material-ui/core/Modal';
import EditIcon from '@material-ui/icons/Edit';

import AddIcon from '@material-ui/icons/Add';

import fetchFirebaseProducteData from './service';

import NewOrUpdate from './NewOrUpdate';
import SearchInput from '../SearchInput'

function getModalStyle() {
    const top = 50;
    const left = 25;

    return {
        top: `${top}px`,
        left: `${left}%`,
        width: '50%'
    };
}
const columns = [
    { id: 'edit', label: <AddIcon />, minWidth: 10, width: 10 },
    { id: 'produto', label: 'Produto', minWidth: 200, width: 250 },
    { id: 'capacidade', label: 'Capacidade', minWidth: 200, width: 250 },
    {
        id: 'quantidade',
        label: 'Quantidade',
        minWidth: 190,
        align: 'right',
        width: 190
    },
    {
        id: 'preco-compra',
        label: 'Compra',
        minWidth: 190,
        align: 'right',
        width: 190
    },
    {
        id: 'preco-venda',
        label: 'Venda',
        minWidth: 190,
        align: 'right',
        width: 190
    },
    {
        id: 'preco-especial',
        label: 'Preço Especial',
        minWidth: 190,
        align: 'right',
        width: 190
    }
];

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 900,
    },
    align: {
        textAlign: 'left'
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        position: 'relative'
    },
    missingCategory: {
        backgroundColor: '#ff00004a'
    },
    missingPhotos: {
        backgroundColor: '#00ff3a47'
    }
}));

const AllProducts = () => {
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);
    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(false);
    const [rows, setRows] = React.useState([]);
    const [idToEdit, setIdToEdit] = React.useState(0);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setIdToEdit(0);
    };

    async function getAllClients() {
        await fetchFirebaseProducteData().then(cli => {
            setRows(cli.data)
        })
    }

    function productAddedOrChanged() {
        getAllClients();
    }

    useEffect(() => {
        getAllClients();
    }, [])

    function getIdToEdit(id) {
        setIdToEdit(id);
        handleOpen()
    }


    return (
        <Paper className={classes.root}>
            <TableContainer className={classes.container}>
                <SearchInput
                    page={'products'}
                    clientsArray={rows}
                    filterClients={(result) => setRows(result)}
                />
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    className={classes.align}
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth, width: column.width }}
                                    onClick={column.id == 'edit' ? handleOpen : null}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}
                                    className={(row.productCategories.length == 0 &&
                                        row.productSubCategories.length == 0)
                                        ? classes.missingCategory :
                                        row.photos.length == 0 ? classes.missingPhotos : null}
                                >
                                    
                                    <TableCell onClick={() => getIdToEdit(row.id)} className={classes.align} style={{ width: columns[0].width }}>
                                        <EditIcon />
                                    </TableCell>
                                    <TableCell className={classes.align} >
                                        {row.name}
                                    </TableCell>
                                    <TableCell className={classes.align}>
                                        {row.capacity}
                                    </TableCell>
                                    <TableCell className={classes.align}>
                                        {row.quantity}
                                    </TableCell>
                                    <TableCell className={classes.align}>
                                        R$ {row.costPrice}
                                    </TableCell>
                                    <TableCell className={classes.align}>
                                        R$ {row.sellPrice}
                                    </TableCell>
                                    <TableCell className={classes.align}>
                                        {row.specialPrice && `R$ ${row.specialPrice}`}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div style={modalStyle} className={classes.paper}>
                    <NewOrUpdate
                        callProdutcsUpdateOrChanged={() => productAddedOrChanged()}
                        closeModal={() => handleClose()}
                        productId={idToEdit}
                    />
                </div>
            </Modal>
        </Paper >
    );
}

export default AllProducts;