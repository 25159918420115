
import * as CONSTANTS from '../constants';
import axios from 'axios';

const url = `${CONSTANTS.URL}/sale`;;

const fetchFirebaseData = async () => {
    // const db = firebase.firestore()
    // return await db.collection('sale').get()
}

export const insertSale = async (customer, products, total) => {

    let productsToSend = [];
    products.forEach(element => {
        productsToSend.push(
            {
                productId: element.id,
                sellPrice: element.precoVenda,
                quantity: element.quantidade
            })
    });

    let body = {
        customerId: customer,
        Products: productsToSend,
        total: total
    }

    return axios.post(`${url}`, body, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionStorage.getItem('token-embuber')}`
        }
    })
}


const updateStock = (db, products) => {

    // products.map(async (prod) => {
    //     await db.collection('products').doc(prod.id).get().then(data => {
    //         let product = data.data();

    //         product.quantidade -= prod.quantidade;

    //          db.collection('products').doc(prod.id).update({
    //             'produto': product.produto,
    //             'capacidade': product.capacidade, 'quantidade': product.quantidade,
    //             'compra': product.compra, 'venda': product.venda
    //         })
    //     });

    // })
}

export default fetchFirebaseData;