const loading = (msg) => {
    return (
        <>
            <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
            <span style={{ margin: '10px' }}>
                {msg}
            </span>

        </>
    )
}

export default loading;