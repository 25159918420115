import axios from 'axios';

const addProductsToLocalStorage = (productToAdd) => {

    if (localStorage.getItem('embalagens-uberlandia-products')) {
        let localStorageJsonItems = JSON.parse(localStorage.getItem('embalagens-uberlandia-products'));
        localStorageJsonItems.push(productToAdd)
        localStorage.setItem('embalagens-uberlandia-products', JSON.stringify(localStorageJsonItems))
    }
    else {
        localStorage.setItem('embalagens-uberlandia-products', JSON.stringify([productToAdd]))
    }
}

export const getProductsOnLocalStorage = () => {
    return JSON.parse(localStorage.getItem('embalagens-uberlandia-products'));
}

export const amountOfProductsOnLocalStorage = () => {
    if (localStorage.getItem('embalagens-uberlandia-products'))
        return JSON.parse(localStorage.getItem('embalagens-uberlandia-products')).length;
}

export const updateLocalStorage = (quantity, capacity, index) => {
    let localStorageJsonItems = JSON.parse(localStorage.getItem('embalagens-uberlandia-products'));
    localStorageJsonItems[index].capacity = capacity;
    localStorageJsonItems[index].quantity = quantity;
    localStorage.setItem('embalagens-uberlandia-products', JSON.stringify(localStorageJsonItems))
}

export const removeItemFromLocalStorage = async (index) => {
    let localStorageJsonItems = JSON.parse(localStorage.getItem('embalagens-uberlandia-products'));
    localStorageJsonItems.splice(index, 1);

    localStorage.setItem('embalagens-uberlandia-products', JSON.stringify(localStorageJsonItems))
}

export const sendBudgetRequestEmail = (contactForm) => {
    let localStorageJsonItems = JSON.parse(localStorage.getItem('embalagens-uberlandia-products'));
    console.log(localStorageJsonItems, contactForm)
    let url = 'https://embalagens-uberlandia-service.azurewebsites.net/budget';
    // let url = 'https://localhost:5001/budget';

    axios.post(url,
        {
            contact: contactForm,
            items: localStorageJsonItems
        }
    )
        .then(function (response) {
            console.log(response);
        })
        .catch(function (error) {
            console.log(error);
        });
}

export default addProductsToLocalStorage;