import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import './nav-bar.css';

import React, { useEffect, useState } from "react";
import {
    BrowserRouter as Router,
    Route,
    Link,
    useParams
} from "react-router-dom";

//service
import { amountOfProductsOnLocalStorage } from '../../service/budget-service';

const NavBar = (props) => {
    const [cardAmountItems, setCardAmountItems] = useState(amountOfProductsOnLocalStorage())
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    useEffect(() => { setCardAmountItems(amountOfProductsOnLocalStorage()) }, [])

    return (
        <>
            <Navbar style={isMenuOpen ? { 'justifyContent': 'center'} : {}} bg="dark" expand="lg" variant="dark">
                <Navbar.Brand className="label-nav-bar" href="#home">Embalages Uberlândia</Navbar.Brand>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        {/* <Nav.Link href="/">Categorias</Nav.Link> */}
                        {/* <Nav.Link href="/">Embalagens Farmacêuticas</Nav.Link>
                        <Nav.Link href="/">Homeopatia</Nav.Link>
                        <Nav.Link href="/">Cosméticos</Nav.Link>
                        <Nav.Link href="/">Bebidas</Nav.Link>
                        <Nav.Link href="/">Tampas/Rolhas/Lacres</Nav.Link>
                        <Nav.Link href="/">Potes para Conservas</Nav.Link>
                        <Nav.Link href="/">Outros Produtos</Nav.Link>
                        <Nav.Link href="/">Quem Somos</Nav.Link>
                        <Nav.Link  href="/">Fale Conosco</Nav.Link> */}

                    </Nav>
                </Navbar.Collapse>
                <Form inline>
                    <Link to={'/'}>
                        {/* <FontAwesomeIcon
                            fill='false'
                            icon={faShoppingCart}
                            color="rgba(255,255,255,.5)"
                            size={'2x'}
                        /> */}
                    </Link>
                    {props.amountOfItemsCard &&
                        <span className="amount-items-card">
                            {props.amountOfItemsCard}
                        </span>
                    }
                    {/* <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setIsMenuOpen(!isMenuOpen)} /> */}
                </Form>
            </Navbar>
        </>
    )
}

export default NavBar;