export const LANDING = '/';
export const SIGN_OUT = '/signout';
export const SIGN_IN = '/signin';
export const HOME = '/home';
export const SALE = '/admin/venda';
export const PRODUCTS = '/admin/produtos';
export const PASSWORD_FORGET = '/pw-forget';
export const CLIENTS = '/admin/clientes';
export const BUY = '/admin/compras';
// export const URL = 'https://localhost:5001';
export const URL = 'https://embuber-service.azurewebsites.net';