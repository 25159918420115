import * as CONSTANTS from '../pages/admin/constants';
import axios from 'axios';


const url = `${CONSTANTS.URL}/auth/login` ;

const LoginService = (user, pass) => {
   return axios.post(url,
        {
            userName: user,
            pass: pass
        }
    )
}

export default LoginService;