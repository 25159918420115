import Jumbotron from 'react-bootstrap/Jumbotron';
import Container from 'react-bootstrap/Container'

const Header = () => {
    return (
        <div>
            {/* <Jumbotron className="jumbotron-improves" style={{ 'background-image': `url(${process.env.PUBLIC_URL + '/images/topo2.jpg'})` }}> */}
                {/* <Container>
                    <div className='header-info' >
                        <h1>Embalagens Uberlândia</h1>
                        <p>Desde 1997 <br></br>
                            Pioneira no comércio de embalagens Farmacêuticas e Alimentícias no Triângulo
                        </p>
                    </div>
                </Container> */}
            {/* </Jumbotron> */}
        </div>
    )
}

export default Header;