import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../constants';
// import Sale from '../Sale'
// import SignOut from '../SignOut'
import Buy from '../buy'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter
} from "react-router-dom";

import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import LocalCafeIcon from '@material-ui/icons/LocalCafe';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

import Sale from '../Sale';
import Products from '../produtcs';
import Report from '../report';
import Clients from '../Clients'


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  sideMenuIten: {
    display: 'flex'
  },
  iconColor: {
    color: '#1e1e7d'
  },
  textDecorationNone: {
    textDecoration: 'none',
    color: '#1e1e7d'
  }
}));

export const MiniDrawer = (props) => {
  const [module, setModule] = useState(props.path);

  useEffect(() => { setModule(props.path.split('/')[2]) }, [props.path])

  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {

    // firebase.firestore().collection('potes')
    //   .get().then(snapshot => {
    //     snapshot.docs.forEach(pote => {
    //       console.log({ 'pote': pote.data(), 'poteId': pote.id })
    //     })
    //   })
  }, [])


  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            Embalagens Uberlândia - {module}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>
          {props.userMail == 'jonestinglin@gmail.com' &&
            <Link className={classes.textDecorationNone} to={ROUTES.SIGN_IN}>
              <ListItem button>
                <ListItemIcon >
                  <InboxIcon className={classes.iconColor} />
                 Backup
                </ListItemIcon>
                <ListItemText primary={'Sign in'} />
              </ListItem>
            </Link>
          }
          <Link className={classes.textDecorationNone} to={ROUTES.PRODUCTS} >
            <ListItem button >
              <ListItemIcon >
                <LocalCafeIcon className={classes.iconColor} />
              </ListItemIcon>
              <ListItemText primary={'Produtos'} />
            </ListItem>
          </Link>

          <Link className={classes.textDecorationNone} to={ROUTES.CLIENTS}>
            <ListItem button >
              <ListItemIcon >
                <PeopleAltIcon className={classes.iconColor} />
              </ListItemIcon>
              <ListItemText primary={'Clientes'} />
            </ListItem>
          </Link>

          <Link className={classes.textDecorationNone} to={ROUTES.SALE}>
            <ListItem button >
              <ListItemIcon >
                <MonetizationOnIcon className={classes.iconColor} />
              </ListItemIcon>
              <ListItemText primary={'Venda'} />
            </ListItem>
          </Link>

          {/* <Link className={classes.textDecorationNone} to={ROUTES.BUY}>
            <ListItem button >
              <ListItemIcon >
                <ShoppingCartIcon className={classes.iconColor} />
              </ListItemIcon>
              <ListItemText primary={'Comprar'} />
            </ListItem>
          </Link> */}
        </List>

      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />


        <Switch>
        <Route path="/admin/venda" render={() => (<Sale />)} />
          <Route path="/admin/produtos" render={() => (<Products />)} />
          <Route path="/admin/compras" render={() => (<Buy />)} />
          <Route path="/admin/clientes" render={() => (<Clients />)} />
          <Route path="/admin" render={() => (<Report />)} />
        </Switch>


        {/* {props.history.location.pathname === ROUTES.SIGN_IN ?
          <SignIn /> :
          props.history.location.pathname === ROUTES.SIGN_OUT ?
            <SignOut /> : */}
        {/* {location.pathname === ROUTES.CLIENTS ?
        //   <Clients />
        "clients"
          :
         location.pathname === ROUTES.SALE ?
            // <Sale />
            "sale"
            :
          location.pathname === ROUTES.BUY ?
            //   <Buy />
            "compra"
              : */}
        {/* } */}
      </main>
    </div>
  );
}


export default withRouter(MiniDrawer);