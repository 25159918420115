
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useEffect, useState } from 'react';

const BudgetForm = (props) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [company, setCompany] = useState('');

    useEffect(() => {
        props.getFormData({ name, email, phone, company })
    }, [name, email, phone, company])

    return (
        <Form>
            <Form.Group controlId="formBasicName">
                <Form.Label>Nome</Form.Label>
                <Form.Control
                    required type="text"
                    placeholder="Digite seu nome..."
                    onChange={(e) => setName(e.target.value)} />
            </Form.Group>

            <Form.Group controlId="formBasicEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                    type="email"
                    placeholder="Digite seu email..."
                    onChange={(e) => setEmail(e.target.value)} />
            </Form.Group>

            <Form.Group controlId="formBasicEmail">
                <Form.Label>Telefone</Form.Label>
                <Form.Control
                    required
                    type="phone"
                    placeholder="Digite o telefone de contato..."
                    onChange={(e) => setPhone(e.target.value)} />
            </Form.Group>

            <Form.Group controlId="formBasicEmail">
                <Form.Label>Empresa</Form.Label>
                <Form.Control
                    required
                    type="text"
                    placeholder="Digite nome da empresa (caso seja necessário)..."
                    onChange={(e) => setCompany(e.target.value)} />
            </Form.Group>
        </Form>
    );
}

export default BudgetForm;