import React, { useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';

// import { updateProduct } from '../Products/service'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 900,
    },
    align: {
        textAlign: 'left'
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        position: 'relative'
    },
    greenBtn: {
        backgroundColor: '#099909'
    },
    actionbuttons: {
        display: 'flex'
    },
    padding: {
        padding: '14px'
    },
    flexSpaceBetween: {
        display: 'flex',
        justifyContent: 'space-arround'
    },
    fontSizeRem: {
        fontSize: '1.6rem',
        padding: '14px 10px'
    },
    totalSale: {
        fontSize: '1.6rem',
        padding: '14px 0px'
    },
    priceClient: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '73%'
    },
    paddingTop: {
        paddingTop: '20px'
    }
}));

const Buy = (props) => {
    const classes = useStyles();
    const [productsList, setProductsList] = React.useState([]);
    const [selectedProduct, setSelectedProduct] = React.useState('');
    const [seletProductToUpdate, setSeletProductToUpdate] = React.useState({});
    const [quantity, setQuantity] = React.useState('');

    useEffect(() => {
        setProductsList(JSON.parse(sessionStorage.getItem('products')));
    }, [])


    const updateStock = () => {
        let selectedProductObj = productsList.filter(p => {
            return p.produto == selectedProduct
        })

        selectedProductObj[0].quantidade = Number(selectedProductObj[0].quantidade) + Number(quantity);

        // updateProduct(selectedProductObj[0].id, selectedProductObj[0].produto, selectedProductObj[0].capacidade,
        //     selectedProductObj[0].quantidade, selectedProductObj[0].compra, selectedProductObj[0].venda)
    }

    return (
        <Paper className={classes.root}>
            <div className={classes.padding}>
                <form className={classes.root} noValidate autoComplete="off">
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel htmlFor="outlined-age-native-simple">Itens</InputLabel>
                        <Select
                            native
                            value={selectedProduct}
                            onChange={(e) => setSelectedProduct(e.target.value)}
                            label="Itens"
                        >
                            <option aria-label="None" value="" />
                            {productsList?.map(prod => {
                                return (<option value={prod.produto}>{prod.produto}</option>)
                            })}
                        </Select>
                    </FormControl>
                    <TextField
                        required
                        id="cliente-telefone"
                        label="Quantidade"
                        value={quantity}
                        variant="outlined"
                        type="number"
                        onChange={(e) => setQuantity(e.target.value)}
                    />
                </form>
                <div className={classes.paddingTop}>
                    <Button onClick={() => updateStock()} variant="contained" color="primary">
                        Gravar
                </Button>
                </div>
            </div>
        </Paper>
    );
}
export default Buy;