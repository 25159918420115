import * as CONSTANTS from '../constants';
import axios from 'axios';


const url = `${CONSTANTS.URL}/products`;;

const fetchFirebaseProducteData = async () => {
    return axios.get(url, {
        headers: { 'Authorization': `Bearer ${sessionStorage.getItem('token-embuber')}` }
    })
}

export const insertProduct = async (produto, capacidade, quantidade, compra, venda, vendaEspecial, categorias, subCategorias, photos, descricao) => {
    let body = {
        name: produto,
        capacity: capacidade,
        quantity: Number(quantidade),
        costPrice: Number(compra),
        sellPrice: Number(venda),
        specialPrice: Number(vendaEspecial),
        Categories: categorias,
        SubCategories: subCategorias,
        photos: photos,
        description: descricao
    }

    return axios.post(`${url}`, body, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionStorage.getItem('token-embuber')}`
        }
    })
}

export const getProductById = async (id) => {
    return axios.get(`${url}/${id}`, {
        headers: { 'Authorization': `Bearer ${sessionStorage.getItem('token-embuber')}` }
    })
}

export const updateProduct = async (id, produto, capacidade, quantidade, compra, venda, vendaEspecial, categorias, subCategorias, photos, descricao) => {
    let body = {
        productId: id,
        name: produto,
        capacity: capacidade,
        quantity: Number(quantidade),
        costPrice: Number(compra),
        sellPrice: Number(venda),
        specialPrice: Number(vendaEspecial),
        categories: categorias,
        SubCategories: subCategorias,
        photos: photos,
        description: descricao
    }

    return axios.put(`${url}/${id}`, body, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionStorage.getItem('token-embuber')}`
        }
    })
}

export const deleteProduct = async (id) => {
    return axios.delete(`${url}/${id}`, {
        headers: { 'Authorization': `Bearer ${sessionStorage.getItem('token-embuber')}` }
    })
}

export default fetchFirebaseProducteData;