import Item from './items/item-section/item';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import loading from '../helper/loading';

import getProductsByCategoryAndSubcategory from "../service/products-service";


const ProductsSection = (props) => {
    const [pagedResult, setPagedResult] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = React.useState(true);
    const [categoria, setCategoria] = React.useState(props.categoryId);
    const [categoriaName, setCategoriaName] = React.useState('');

    const productsPerPage = 10;

    useEffect(async () => {
        let cat = getCategoryIdWhenRefreshBrowser();

        setCategoriaName(cat);

        await getProductsByCategoryAndSubcategory(productsPerPage, currentPage, cat, cat)
            .then(x => {
                setPagedResult(x.data);
                setIsLoading(false)
            });
    }, [])

    useEffect(async () => {
        let cat = getCategoryIdWhenRefreshBrowser();

        setPagedResult([])
        setIsLoading(true)
        setCategoriaName(cat);
        await getProductsByCategoryAndSubcategory(productsPerPage, currentPage, cat, cat)
            .then(x => {
                setPagedResult(x.data);
                setIsLoading(false)
            });
    }, [currentPage])

    const func1 = (quantity) => props.updateAmountToApp(quantity)


    const getCategoryIdWhenRefreshBrowser = () => {

        let urlSplit = window.location.href.split('/');
        let urlCategory = urlSplit[urlSplit.length - 1];

        switch (urlCategory) {
            case 'embalagensfarmaceuticas':
                return 1;
            case 'homeopatia':
                return 2;
            case 'cosmeticos':
                return 3;
            case 'bebidas':
                return 4;
            case 'tampasrolhaslacres':
                return 5;
            case 'potesparaconservas':
                return 6;
            case 'outrosprodutos':
                return 7;
            default:
                console.log(`Sorry, we are out of ${urlCategory}.`);
        }
    }

    const getCategoryNameWhenRefreshBrowser = () => {

        let urlSplit = window.location.href.split('/');
       return urlSplit[urlSplit.length - 1];
    }

    const getSelectedPageStyle = (selectedPage) => {
        return currentPage == selectedPage
            ? "selected-page-pagination"
            : "page-link"
    }

    return (
        <>
            <div className="display-items">
                {isLoading && loading('Carregando produtos...')}
                <Row>
                    {pagedResult.results && pagedResult.results.map((p, i) => {
                        return (
                            <Col key={i} xs={6} md={3} lg={3} xl={2} className="display-flex">
                                <div className="item-box" key={i}>
                                    <Item product={p} updateAmountToProductSection={(quantity) => func1(quantity)} categoryName={getCategoryNameWhenRefreshBrowser()} />
                                </div>
                            </Col>
                        );
                    })}
                </Row>
            </div>

            {pagedResult.pageCount && pagedResult.pageCount != 1 &&

                <div className='pagination-bootstrap'>
                    <nav aria-label="Page navigation example">
                        <ul class="pagination">
                            <li class="page-item">
                                <a class="page-link" aria-label="Previous" onClick={() => setCurrentPage(currentPage - 1)}>
                                    <span aria-hidden="true">&laquo;</span>
                                    <span class="sr-only">Previous</span>
                                </a>
                            </li>

                            {pagedResult.pageCount == 2 &&
                                <>
                                    <li class="page-item"><a className={getSelectedPageStyle(1)} onClick={() => setCurrentPage(1)}>1</a></li>
                                    <li class="page-item"><a className={getSelectedPageStyle(pagedResult.pageCount)} onClick={() => setCurrentPage(pagedResult.pageCount)}>{pagedResult.pageCount}</a></li>
                                </>
                            }

                            {pagedResult.pageCount == 3 &&
                                <>
                                    <li class="page-item"><a className={getSelectedPageStyle(1)} onClick={() => setCurrentPage(1)}>1</a></li>
                                    <li class="page-item"><a className={getSelectedPageStyle(2)} onClick={() => setCurrentPage(2)}>2</a></li>
                                    <li class="page-item"><a className={getSelectedPageStyle(pagedResult.pageCount)} onClick={() => setCurrentPage(pagedResult.pageCount)}>{pagedResult.pageCount}</a></li>
                                </>
                            }


                            {pagedResult.pageCount > 3 &&
                                <>
                                    <li class="page-item"><a className={getSelectedPageStyle(1)} onClick={() => setCurrentPage(1)}>1</a></li>
                                    <li class="page-item"><a className={getSelectedPageStyle(2)} onClick={() => setCurrentPage(2)}>2</a></li>
                                    <li class="page-item"><a className={getSelectedPageStyle(3)} onClick={() => setCurrentPage(3)}>3</a></li>
                                    <li class="page-item"><a class="page-link" >...</a></li>
                                    <li class="page-item"><a className={getSelectedPageStyle(pagedResult.pageCount)} onClick={() => setCurrentPage(pagedResult.pageCount)}>{pagedResult.pageCount}</a></li>
                                </>
                            }



                            <li class="page-item">
                                <a className={"page-link"} aria-label="Next" onClick={() => currentPage != pagedResult.pageCount && setCurrentPage(currentPage + 1)}>
                                    <span aria-hidden="true">&raquo;</span>
                                    <span class="sr-only" >Next</span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            }

            <div>
                <Link to={`/`}>
                    <Button variant="info">Voltar</Button>
                </Link>
            </div>

        </>
    )
}

export default ProductsSection;