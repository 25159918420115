import './App.css';
import Header from './components/header';
import ProductsSection from './components/products-section';
import NavBar from './components/nav-bar/nav-bar';
import Footer from './components/footer/footer';
import Category from './components/category/category';
import Top from './components/top/top';
import Card from './pages/card';
import Admin from './pages/admin/admin';
import Buy from './pages/admin/buy'
import ItemDetail from './components/items/item-detail/item-detail';

import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect, useState } from 'react';

import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";


import { MiniDrawer } from './pages/admin/navigation';

import { amountOfProductsOnLocalStorage } from '../src/service/budget-service';
import { render } from '@testing-library/react';

function App() {
  const [category, setCategory] = useState(0);
  const [amountOfItemsCard, setAmountOfItemsCard] = useState(amountOfProductsOnLocalStorage());
  const [currentPath, setCurrentPath] = useState("");

  const updateAmountProductSection = (quantity) => {
    setAmountOfItemsCard(quantity)
  }

  const updateCartItemNumbers = () => {
    setAmountOfItemsCard(amountOfProductsOnLocalStorage());
  }

  const selectCategory = (categoryId) => {
    setCategory(categoryId)
  }

  const manutenceSite = () => {
    return (
      <div className="App">
        <div className="content-app">
          Site em construção
        </div>
      </div>
    )
  }

  return (
    <>
      <Router>
        <Route path="/admin" render={() => (<Admin setCurrentPath={(path) => setCurrentPath(path)} />)} />
      </Router>

      <div className="App">
        <div className="content-app">

          {!currentPath.includes("/admin") ?
            <>
              {/* Site em construção. */}
              
              <Router>
                <Top />
                <Header />
                <NavBar amountOfItemsCard={amountOfItemsCard} />
                <Switch>
                  <Route exact path="/">
                    <Category changeCategory={(cat) => selectCategory(cat)} />
                  </Route>
                  <Route path="/categoria/:category" render={() => (<ProductsSection
                    categoryId={category}
                    updateAmountToApp={(quantity) =>
                      updateAmountProductSection(quantity)} />)} />

                  <Route path="/produto/:product" render={() => (<ItemDetail />)} />

                  <Route path="/orcamento" render={() => (<Card updateCartItemNumbers={() => updateCartItemNumbers()} />)} />
                </Switch>
              </Router>
              <Footer />
            </>
            : null}

        </div>
      </div>
    </>
  );
}

export default App;