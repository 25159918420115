import CATEGORY_CONSTANTS from './utils/constants';

const products = [{
    id: 1,
    name: 'Frasco + Cânula + bulbo',
    capacity: [
        '60ml',
        '90ml'
    ],
    description: 'Frasco completo',
    category: CATEGORY_CONSTANTS.FRASCO_VIDRO,
    images: [
        '../../emb_images/ambar_conta_gota.jpg',
        'https://cdn.juvasa.com/contenidos/articulos/b-212-212ml-to-063.jpg'
    ]
}
, {
    id: 2,
    name: 'Frasco + Válvula Spray',
    capacity: [
        '60ml',
        '90ml',
        '120ml',
        '160ml'
    ],
    category: CATEGORY_CONSTANTS.FRASCO_VIDRO,
    description: 'Frasco completo',
    images: [
        '../../emb_images/ambar_spray.png',
        'image 2'
    ]
}, {
    id: 3,
    name: 'Frasco Ambar Avulso',
    capacity: [
        '60ml',
        '90ml',
        '120ml',
        '160ml'
    ],
    category: CATEGORY_CONSTANTS.FRASCO_VIDRO,
    description: 'Frasco Ambar Avulso. Somente frasco',
    images: [
        '../../emb_images/so_ambar.jpg',
        'image 2'
    ]
}, {
    id: 4,
    name: 'Rolom Ambar',
    capacity: [
        '60ml',
        '90ml',
        '120ml',
        '160ml'
    ],
    category: CATEGORY_CONSTANTS.FRASCO_VIDRO,
    description: 'Rolom Ambar',
    images: [
        'https://canary.contestimg.wish.com/api/webimage/5b152225cee36e69593e6734-large.jpg?cache_buster=1d1fb403c7695de916f0504d84471a3b',
        'image 2'
    ]
}
, 
{
    id: 5,
    name: 'Pode Conserva 600 ML + Tampa',
    capacity: [
        '600ml'
    ],
    category: CATEGORY_CONSTANTS.POTE_VIDRO,
    description: 'Pote 600 Ml com tampa',
    images: [
        'https://www.latiendadelapicultor.com/1297-large_default/embalagem-05kg-liso-a370.webp',
        'image 2'
    ]
}, {
    id: 6,
    name: 'Bisnaga Plastica + Tampa',
    capacity: [
        '60ml',
        '90ml'
    ],
    description: 'esta é a descrição do produto 1',
    category: CATEGORY_CONSTANTS.BISNAGA,
    images: [
        'https://ae01.alicdn.com/kf/H25a6feec54bd4c24b1350d5dc59f802er/10-unidades-pacote-vazio-recipiente-macio-para-tubo-de-esmalte-brilho-labial-garrafas-recarreg-veis-maquiagem.jpg',
        'https://cdn.juvasa.com/contenidos/articulos/b-212-212ml-to-063.jpg'
    ]
}, {
    id: 7,
    name: 'Pote Conserva sem Tampa',
    capacity: [
        '60ml',
        '90ml'
    ],
    description: 'Somente pote. Não companha tampa',
    category: CATEGORY_CONSTANTS.POTE_VIDRO,
    images: [
        '../../emb_images/600_sem_tampa.jpg',
        'https://cdn.juvasa.com/contenidos/articulos/b-212-212ml-to-063.jpg'
    ]
}, {
    id: 8,
    name: 'produto 8',
    capacity: [
        '60ml',
        '90ml'
    ],
    description: 'esta é a descrição do produto 1',
    category: CATEGORY_CONSTANTS.ALIMENTICIO,
    images: [
        'https://www.latiendadelapicultor.com/1297-large_default/embalagem-05kg-liso-a370.webp',
        'https://cdn.juvasa.com/contenidos/articulos/b-212-212ml-to-063.jpg'
    ]
}, {
    id: 9,
    name: 'produto 9',
    capacity: [
        '60ml',
        '90ml'
    ],
    description: 'esta é a descrição do produto 1',
    category: CATEGORY_CONSTANTS.ALIMENTICIO,
    images: [
        'https://www.latiendadelapicultor.com/1297-large_default/embalagem-05kg-liso-a370.webp',
        'https://cdn.juvasa.com/contenidos/articulos/b-212-212ml-to-063.jpg'
    ]
}, {
    id: 10,
    name: 'produto 10',
    capacity: [
        '60ml',
        '90ml'
    ],
    description: 'esta é a descrição do produto 1',
    category: CATEGORY_CONSTANTS.ALIMENTICIO,
    images: [
        'https://www.latiendadelapicultor.com/1297-large_default/embalagem-05kg-liso-a370.webp',
        'https://cdn.juvasa.com/contenidos/articulos/b-212-212ml-to-063.jpg'
    ]
}
, {
    id: 11,
    name: 'Bisnaga Plastica Sem Tampa',
    capacity: [
        '60ml',
        '90ml'
    ],
    description: 'esta é a descrição do produto 1',
    category: CATEGORY_CONSTANTS.BISNAGA,
    images: [
        'https://ae01.alicdn.com/kf/H25a6feec54bd4c24b1350d5dc59f802er/10-unidades-pacote-vazio-recipiente-macio-para-tubo-de-esmalte-brilho-labial-garrafas-recarreg-veis-maquiagem.jpg',
        'https://cdn.juvasa.com/contenidos/articulos/b-212-212ml-to-063.jpg'
    ]
}]

export default products;