import React, { useEffect, useRef, useState } from "react";

import Chart from 'chart.js'


function Report() {
    const [xx, setx] = useState(100)

    const x = () => {
        var data = {
            labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
            datasets: [{
                type: 'line',
                label: "Faturamento",
                backgroundColor: "rgba(10,135,259,0.2)",
                borderColor: "rgba(10,135,259,1)",
                borderWidth: 2,
                hoverBackgroundColor: "rgba(10,135,259,0.4)",
                hoverBorderColor: "rgba(10,135,259,1)",
                data: [65, 59, 20, 81, 56, 55, 40],
            }]
        };


        var data2 = {
            labels: ["Instagran", "Loja", "Orçamento via site"],
            datasets: [{
                type: 'bar',
                label: "Numero de Vendas",
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                ],
                borderWidth: 2,
                hoverBackgroundColor: "rgba(10,135,259,0.4)",
                hoverBorderColor: "rgba(10,135,259,1)",
                data: [65, 59, 20,],
            }]
        };


        var options = {
            maintainAspectRatio: false,
            scales: {
                yAxes: [{
                    stacked: true,
                    gridLines: {
                        display: true,
                        color: "rgba(255,99,132,0.2)"
                    }
                }],
                xAxes: [{
                    gridLines: {
                        display: false
                    }
                }]
            }
        };

        Chart.Bar('chart', {
            options: options,
            data: data,

        });


        
        Chart.Bar('chart2', {
            options: options,
            data: data2,

        });

    }
    const ff = () => {
        return (
            <div class="wrapper">
                <canvas id="myChart" width={xx} height={xx}></canvas>
            </div>
        )
    }


    useEffect(() => { x(); }, [])



    return (
        <>
            <div class="chart-container">
                <canvas id="chart"></canvas>
            </div>
            <div class="chart-container">
                <canvas id="chart2"></canvas>
            </div>
        </>
    );
}

export default Report;