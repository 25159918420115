import Navbar from 'react-bootstrap/Navbar';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

import './top.css';

const Top = () => {
    return (
        <>
            <Navbar bg="dark" expand="lg" variant="dark" className="find-input">
                <Form inline className="form-search-info-section">
                    <div className='top-info-search-content'>
                        <div className='infos-section'>
                            <span>
                                (34) 3214-4232
                            </span>
                            <span>
                                comercial@embalagensuberlandia.com.br
                            </span>
                        </div>
                        <a className='whatsapp-anchor' href='https://api.whatsapp.com/send?phone=5534987004973&text=Ol%C3%A1,%20acessei%20seu%20Link%20Personalizado%20What%27s%20Link'>
                            <div className='whatsapp'>
                                <img src="whatsapp.png" /> Fale com um atendende via Whatsapp!
                            </div>
                        </a>
                        <div className='search-section'>
                            {/* <FormControl type="text" disabled={true} placeholder="Procurar produto" className="mr-sm-2" />
                            <Button variant="outline-info" disabled={true}>
                                <FontAwesomeIcon
                                    fill='false'
                                    icon={faSearch}
                                    color="rgba(255,255,255,.5)"
                                    size={'1x'}
                                />
                            </Button> */}
                        
                        </div>
                        <div></div>
                    </div>
                </Form>
            </Navbar>
        </>
    )
}

export default Top;