import { useEffect, useState } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import Tooltip from 'react-bootstrap/Tooltip';

import React from "react";
import { Link } from "react-router-dom";

import { getProductsById } from '../../../service/products-service';

import loading from '../../../helper/loading';

import './item-detail.css'

const ItemDetail = () => {
    const [product, setProduct] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        let productIdUrl = getProductIdOnUrl();

        getProductsById(productIdUrl)
            .then(res => {
                setProduct(res.data);
                setIsLoading(false);
            })
            .catch(e => console.log(e))
    }, [])

    const getProductIdOnUrl = () => {
        let urlSplit = window.location.href.split('/');
        return urlSplit[urlSplit.length - 1];
    }


    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Orçar
        </Tooltip>
    );

    return (
        <div className='producr-detail-div'>
            {isLoading ? loading('Carregando informações...') :
                <div>
                    <div className='name-section'>
                        <div className='product-name'>{product.name}</div>
                    </div>
                    <div className='item-detail-section'>
                        <div className='item-detail-photo-section'>
                            <div className='item-detail-photo'>
                                {product?.photos && <img src={product?.photos[0]?.photo} />}
                            </div>
                        </div>

                        <div className='item-detail-info-section'>

                            <div className='item-detail-description'>
                                {product?.description?.split("\n").map((d, i) => {
                                    return (<span>{d}</span>)
                                })}
                            </div>
                            <br />
                            <div className='item-detail-capacity'>
                                <span className='span-capacidade'>Capacidade:&nbsp;</span>
                                <span>
                                    {product?.capacity}
                                </span>
                            </div>
                        </div>


                        {/* {JSON.stringify(product)} */}
                    </div>
                    <div className='button-orcar-section'>
                        <div className='orcar-button'>
                            <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip}
                            >
                                <div className="orcar-icon" onClick={() => alert("asooou")}>
                                    <FontAwesomeIcon
                                        fill='false'
                                        icon={faShoppingCart}
                                        color="rgba(255,255,255,.8)"
                                        size={'2x'}
                                    />
                                    Adicionar ao Orçamento
                                </div>
                            </OverlayTrigger>

                        </div>
                    </div>
                </div>}
        </div>
    )
}

export default ItemDetail;