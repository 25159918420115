import React, { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form';
import { updateLocalStorage, removeItemFromLocalStorage } from '../service/budget-service';
import products from '../database/products';
import Col from 'react-bootstrap/Col';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'

const CartRow = (props) => {
    const [quantity, setQuantity] = useState(props.item.quantity);
    const [capacity, setCapacity] = useState(props.item.capacity);

    const [capacityVariations, setCapacityVariations] = useState([]);

    useEffect(() => {
        let capacityVariationItem = products.filter(p => {
            return p.id === props.item.id;
        })

        setCapacityVariations(capacityVariationItem[0].capacity)
    }, [])

    useEffect(() => {
        updateLocalStorage(quantity, capacity, props.i)
    }, [quantity, capacity])

    const removeItem = () => {
        removeItemFromLocalStorage(props.i);
        props.updateRowsOnCart();
        props.updateCartItemQuantity();
    }

    return (<tr key={props.i}>
        <td>{props.i + 1}</td>
        <td>
            <div className="fit-image-cart">
                <img src={props.item.image} />
            </div>
            <span> {props.item.name}</span>
        </td>
        <td>
            <div className="align-items-cart">

                <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Control
                        as="select"
                        onChange={e => setCapacity(e.target.value)}
                        value={capacity}
                    >
                        {capacityVariations.map((c, i) => {
                            return (<option key={i}>{c}</option>)
                        })}
                    </Form.Control>
                </Form.Group>
            </div>
        </td>
        <td>
            <div className="align-items-cart">

                <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Control
                        className="input-small"
                        type="number"
                        placeholder="quantidade"
                        onChange={(e) => setQuantity(e.target.value)}
                        value={quantity}
                    />

                </Form.Group>
            </div>
        </td>
        <td>
            <div className="align-items-cart" onClick={() => removeItem()}>
                <FontAwesomeIcon
                    fill='false'
                    icon={faTimesCircle}
                    color="red"
                    size={'2x'}
                />
            </div>
        </td>
    </tr>)
}

export default CartRow;