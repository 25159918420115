import * as CONSTANTS from '../pages/admin/constants';
import axios from 'axios';


const url = `${CONSTANTS.URL}/site/Products`;

const getProductsByCategoryAndSubcategory = async (pageSize, pageIndex, categoryId, subCategoryId) => {
    return await axios.get(`${url}?pageSize=${pageSize}&pageIndex=${pageIndex}`, {
        params: {
            categoryId: categoryId
        }

    })
}

export const getProductsById = async (id) => {
    return await axios.get(`${url}/${id}`)
}


export default getProductsByCategoryAndSubcategory;