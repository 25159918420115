import React, { useEffect } from 'react';

import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import CurrencyInput from '../components/currencyInput';
import { insertProduct, getProductById, updateProduct, deleteProduct } from './service'

import Resizer from "react-image-file-resizer";

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    flex: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    btnStyle: {
        '& .MuiButton-label': {
            width: '50px',
        },
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '20px'
    },
    btnSpace: {
        marginRight: '10px'
    },
    categoryContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    catInside: {
        display: 'flex',
        flexDirection: 'column'
    },
    categoryPhoto: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    photo: {

    },
    fileInput: {

    },
    descricaoInput:{
        width: '100% !important'
    }
}));


const NewOrUpdate = (props) => {
    const classes = useStyles();
    const [produto, setProduto] = React.useState('');
    const [capacidade, setCapacidade] = React.useState('');
    const [descricao, setDescricao] = React.useState('');
    const [quantidade, setQuantidade] = React.useState('');
    const [compra, setCompra] = React.useState('');
    const [venda, setVenda] = React.useState('');
    const [vendaEspecial, setVendaEspecial] = React.useState('');
    const [categorias, setCategorias] = React.useState([]);
    const [subCategorias, setSubCategorias] = React.useState([]);
    const [photos, setPhotos] = React.useState([]);

    const [isLoading, setIsLoading] = React.useState(false);
    const [waitingBackEndReponseOnMount, setWaitingBackEndReponseOnMount] = React.useState(false);


    useEffect(() => {
        if (props.productId) {
            fillInputs();
            setWaitingBackEndReponseOnMount(true)
        }

    }, [])

    function fillInputs() {
        getProductById(props.productId).then(response => {
            setProduto(response.data.name)
            setCapacidade(response.data.capacity)
            setQuantidade(response.data.quantity)
            setCompra(response.data.costPrice)
            setVenda(response.data.sellPrice)
            setVendaEspecial(response.data.specialPrice)
            setCategorias(response.data.categories.map(x => x.id))
            setSubCategorias(response.data.subCategories.map(x => x.id))
            setPhotos(response.data.photos.map(x => x.photo))
            setDescricao(response.data.description)
            setWaitingBackEndReponseOnMount(false);
        })
    }

    function isertOrUpdateUser() {
        setIsLoading(true);
        if (props.productId) {
            updateProduct(props.productId, produto, capacidade, quantidade, compra, venda, vendaEspecial, categorias, subCategorias, photos, descricao)
                .then(() => {
                    props.callProdutcsUpdateOrChanged();
                    props.closeModal();
                    setIsLoading(false);
                })


        } else {
            setIsLoading(true);
            insertProduct(produto, capacidade, quantidade, compra, venda, vendaEspecial, categorias, subCategorias, photos, descricao)
                .then(() => {
                    props.callProdutcsUpdateOrChanged();
                    props.closeModal();
                    setIsLoading(false);
                })
        }
    }

    function removeProduct(id) {
        setIsLoading(true);
        deleteProduct(id).then(() => {
            props.callProdutcsUpdateOrChanged();
            props.closeModal();
            setIsLoading(false);
        })
    }

    function toogleOnOff(categoryId) {
        if (categorias.indexOf(categoryId) != -1) {
            setCategorias(categorias.filter(x => x != categoryId))
        } else {
            setCategorias([...categorias, categoryId])
        }
    }

    function toogleOnOffSubCat(subCategoryId) {
        if (subCategorias.indexOf(subCategoryId) != -1) {
            setSubCategorias(subCategorias.filter(x => x != subCategoryId))
        } else {
            setSubCategorias([...subCategorias, subCategoryId])
        }
    }

    const changePhoto = async (event) => {
        let file = event.target.files[0];
        var resizedImage = await resizeFile(file);
        setPhotos([...photos, resizedImage]);
    };

    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                600,
                600,
                "WEBP",
                85,
                0,
                (uri) => {
                    resolve(uri);
                },
                "base64"
            );
        });

    const removePhoto = (i) => {
        var arr = photos.splice(i, 1);
        var rest = photos.filter(p => p != arr)
        setPhotos(rest)
    }

    return (
        <>
            {waitingBackEndReponseOnMount
                ? <div class="d-flex align-items-center">
                    <strong>Loading...</strong>
                    <div class="spinner-border ml-auto" role="status" aria-hidden="true"></div>
                </div>
                : <div className={classes.flex}>
                    <form className={classes.root} noValidate autoComplete="off">
                        <div>
                            <TextField
                                required
                                id="cliente-nome"
                                label="Produto"
                                variant="outlined"
                                value={produto}
                                onChange={(e) => setProduto(e.target.value)}
                            />
                            <TextField
                                required
                                id="cliente-telefone"
                                label="Quantidade"
                                value={quantidade}
                                variant="outlined"
                                type="number"
                                onChange={(e) => setQuantidade(e.target.value)}
                            />
                            <TextField
                                required
                                id="cliente-email"
                                label="Capacidade"
                                value={capacidade}
                                variant="outlined"
                                onChange={(e) => setCapacidade(e.target.value)}
                            />

                            <div class="MuiFormControl-root MuiTextField-root">
                                <label class="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-outlined MuiFormLabel-filled"
                                    data-shrink="true"
                                    for="Compra"
                                    id="Compra-label">Compra</label>
                                <div class="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-formControl">

                                    <CurrencyInput
                                        className="MuiInputBase-input MuiOutlinedInput-input custom"
                                        placeholder="$0.00"
                                        type="text"
                                        value={compra}
                                        onChange={(e) => setCompra(e.target.value)}
                                        id="Compra"
                                        label="Compra"
                                        variant="outlined" />
                                </div>
                            </div>

                            <div class="MuiFormControl-root MuiTextField-root">
                                <label class="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-outlined MuiFormLabel-filled"
                                    data-shrink="true"
                                    for="preco-venda"
                                    id="preco-venda-label">Venda</label>
                                <div class="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-formControl">

                                    <CurrencyInput
                                        class="MuiInputBase-input MuiOutlinedInput-input custom"
                                        placeholder="$0.00"
                                        type="text"
                                        value={venda}
                                        onChange={(e) => setVenda(e.target.value)}
                                        id="preco-venda"
                                        label="Preço Especial"
                                        variant="outlined" />
                                </div>
                            </div>

                            <div class="MuiFormControl-root MuiTextField-root">
                                <label class="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-outlined MuiFormLabel-filled"
                                    data-shrink="true"
                                    for="preci-especial"
                                    id="preci-especial-label">Preço Especial</label>
                                <div class="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-formControl">

                                    <CurrencyInput
                                        class="MuiInputBase-input MuiOutlinedInput-input custom"
                                        placeholder="$0.00"
                                        type="text"
                                        value={vendaEspecial}
                                        onChange={(e) => setVendaEspecial(e.target.value)}
                                        id="preci-especial"
                                        label="Preço Especial"
                                        variant="outlined" />
                                </div>
                            </div>
                            <br />
                            <div>
                                <TextField
                                    className={classes.descricaoInput}
                                    required
                                    id="descricao"
                                    label="Descrião completa"
                                    multiline
                                    rows={4}
                                    value={descricao}
                                    variant="outlined"
                                    onChange={(e) => setDescricao(e.target.value)}
                                />
                            </div>
                            <br />
                            <br />

                            <div className={classes.categoryPhoto}>


                                <div className={classes.categoryContainer}>
                                    <div className={classes.catInside}>
                                        <h4>Categorias</h4>
                                        <div class="MuiFormControl-root MuiTextField-root">
                                            <FormGroup>
                                                jaoo
                                                <FormControlLabel control={<Switch onChange={() => toogleOnOff(1)} checked={categorias.indexOf(1) != -1} />} label="Embalagens Farmacêuticas" />
                                                <FormControlLabel control={<Switch onChange={() => toogleOnOff(2)} checked={categorias.indexOf(2) != -1} />} label="Homeopatia" />
                                                <FormControlLabel control={<Switch onChange={() => toogleOnOff(3)} checked={categorias.indexOf(3) != -1} />} label="Costméticos" />
                                                <FormControlLabel control={<Switch onChange={() => toogleOnOff(4)} checked={categorias.indexOf(4) != -1} />} label="Bebidas" />
                                                <FormControlLabel control={<Switch onChange={() => toogleOnOff(5)} checked={categorias.indexOf(5) != -1} />} label="Tampas/Rolhas/Lacres" />
                                                <FormControlLabel control={<Switch onChange={() => toogleOnOff(6)} checked={categorias.indexOf(6) != -1} />} label="Potes para Conservas" />
                                                <FormControlLabel control={<Switch onChange={() => toogleOnOff(7)} checked={categorias.indexOf(7) != -1} />} label="Outros Produtos" />
                                            </FormGroup>
                                        </div>
                                    </div>

                                    {/* <div className={classes.catInside}>
                                        <h4>Tipo de Produto</h4>
                                        <div class="MuiFormControl-root MuiTextField-root">
                                            <FormGroup>
                                                <FormControlLabel style={{ width: '260px' }} control={<Switch onChange={() => toogleOnOffSubCat(1)} checked={subCategorias.indexOf(1) != -1} />} label="Potes Plásticos" />
                                                <FormControlLabel control={<Switch onChange={() => toogleOnOffSubCat(2)} checked={subCategorias.indexOf(2) != -1} />} label="Potes Vidro" />
                                                <FormControlLabel control={<Switch onChange={() => toogleOnOffSubCat(3)} checked={subCategorias.indexOf(3) != -1} />} label="Frascos Plásticos" />
                                                <FormControlLabel control={<Switch onChange={() => toogleOnOffSubCat(4)} checked={subCategorias.indexOf(4) != -1} />} label="Frascos Vidro" />
                                                <FormControlLabel control={<Switch onChange={() => toogleOnOffSubCat(5)} checked={subCategorias.indexOf(5) != -1} />} label="Conta Gotas Plastico" />
                                                <FormControlLabel control={<Switch onChange={() => toogleOnOffSubCat(6)} checked={subCategorias.indexOf(6) != -1} />} label="Conta Gotas Vidro" />
                                                <FormControlLabel control={<Switch onChange={() => toogleOnOffSubCat(7)} checked={subCategorias.indexOf(7) != -1} />} label="Tampas Metal" />
                                                <FormControlLabel control={<Switch onChange={() => toogleOnOffSubCat(8)} checked={subCategorias.indexOf(8) != -1} />} label="Tampas Plásticas" />
                                                <FormControlLabel control={<Switch onChange={() => toogleOnOffSubCat(9)} checked={subCategorias.indexOf(9) != -1} />} label="Frascos Acrílicos" />
                                                <FormControlLabel control={<Switch onChange={() => toogleOnOffSubCat(10)} checked={subCategorias.indexOf(10) != -1} />} label="Frascos Pets" />
                                                <FormControlLabel control={<Switch onChange={() => toogleOnOffSubCat(11)} checked={subCategorias.indexOf(11) != -1} />} label="Válvulas" />
                                                <FormControlLabel control={<Switch onChange={() => toogleOnOffSubCat(12)} checked={subCategorias.indexOf(12) != -1} />} label="Rolhas" />
                                                <FormControlLabel control={<Switch onChange={() => toogleOnOffSubCat(13)} checked={subCategorias.indexOf(13) != -1} />} label="Latinhas" />
                                            </FormGroup>
                                        </div>
                                    </div> */}
                                </div>
                                <div className={classes.photo}>
                                    <div>
                                        {/* <label for="add-file">Choose a file</label> */}
                                        <input
                                            className={classes.fileInput}
                                            onChange={(e) => changePhoto(e)}
                                            type="file"
                                            accept="image/png, image/jpeg"
                                            multiple
                                            id="add-file"
                                            style={{ width: '800px' }}>

                                        </input>
                                    </div>
                                    {photos.map((p, i) =>
                                        <div key={i}>
                                            <img src={p} width="100px" />
                                            <Button onClick={() => removePhoto(i)} variant="contained" style={{ backgroundColor: 'red', color: '#FFFFFF', minWidth: '0' }}>x</Button>
                                        </div>
                                    )}
                                </div>

                            </div>

                        </div>
                    </form>

                    <div className={classes.btnStyle}>
                        {props.productId != 0 ?
                            <div className={classes.btnSpace}>
                                <Button disabled={isLoading} onClick={() => removeProduct(props.productId)} variant="contained" color="secondary">
                                    {isLoading
                                        ? 'Salvando...'
                                        : 'Delete'}
                                </Button>
                            </div>
                            : null}
                        <Button disabled={isLoading} onClick={() => isertOrUpdateUser()} variant="contained" color="primary">
                            {isLoading
                                ? 'Salvando...'
                                : 'Salvar'}
                        </Button>
                    </div>
                </div>
            }
        </>
    );
}
export default NewOrUpdate;