import Card from 'react-bootstrap/Card';
import Carousel from 'react-bootstrap/Carousel';
import Modal from 'react-bootstrap/Modal';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import { Link } from "react-router-dom";

import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';

import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons'

import initialStateItemsForm from '../../../utils/initialFormItems';

import './item-section.css'

//service
import addProductsToLocalStorage, { amountOfProductsOnLocalStorage } from '../../../service/budget-service';

const Item = (props) => {
    const [isPhotos, setIsPhoto] = useState(0);
    const [show, setShow] = useState(false);
    const [isProductAdded, setIsProductAdded] = useState(false);
    const [itemsVariationFormNumber, seTitemsVariationFormNumber] = useState([initialStateItemsForm]);

    //Product to card
    const [quantity, setQuantity] = useState(0);
    const [capacity, setCapacity] = useState(props.product.capacity[0]);


    const handleClose = () => {
        setShow(false);
        setIsProductAdded(false)
    }

    const handleAddProductModal = () => {
        setIsProductAdded(true)
        addProductsToLocalStorage({
            id: props.product.id,
            image: props.product.images[0],
            name: props.product.name,
            quantity: quantity,
            capacity: capacity
        })
        props.updateAmountToProductSection(amountOfProductsOnLocalStorage())
    }

    const handleShow = () => {
        setShow(true);
        setIsPhoto(true);
    }

    const handleShowQuantityAndVariation = () => {
        setShow(true);
        setIsPhoto(false);
    }

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Orçar
        </Tooltip>
    );

    const handleAddNewVariationForm = (item) => {
        if (initialStateItemsForm.length == 1 && initialStateItemsForm[0].productId == 0)
            seTitemsVariationFormNumber([item])
        else
            seTitemsVariationFormNumber([...itemsVariationFormNumber, item])
    }

    const productAddForm = () => {
        return (
            <>
                <Col xs={12} md={6} lg={6} className="display-flex">
                    <Form.Group controlId="exampleForm.ControlInput1">
                        <Form.Label>Quantidade</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="quantidade"
                            onChange={(e) => setQuantity(e.target.value)} />
                    </Form.Group>
                </Col>
                <Col xs={12} md={6} lg={6} className="display-flex">
                    <Form.Group controlId="exampleForm.ControlSelect1">
                        <Form.Label>Capacidade</Form.Label>
                        {/* <Form.Control
                            as="select"
                            onChange={e => setCapacity(e.target.value)}>
                            {props.product.capacity.map((c, i) => {
                                return (<option key={i}>{c}</option>)
                            })}
                        </Form.Control> */}
                    </Form.Group>
                </Col>
            </>
        )
    }

    const toTitleCase = (string) => {
        return string.replace(/([^\s:\-])([^\s:\-]*)/g, function ($0, $1, $2) {
            return $1.toUpperCase() + $2.toLowerCase();
        })
    }


    const getProductImage = (photo) => {
        return photo
            ? photo
            : '/images/no-image-portrait.jpeg'
    }

    return (
        <div>
            <Card className="heigh-card" style={{ width: '18rem' }}>
                <Link to={`/produto/${props.product.id}`}>
                    <div className='padding-item-image'>
                        <Card.Img  variant="top" src={getProductImage(props.product?.photos[0]?.photo)} />
                    </div>
                    <Card.Body>
                        <Card.Title>{toTitleCase(props.product.name)}</Card.Title>
                        <Card.Text>
                            {props.product.capacity
                                ? <span> {props.product.capacity} </span>
                                : <span> &nbsp; </span>}
                        </Card.Text>
                        <div className="item-description">
                            <Card.Text>{props.product.description}</Card.Text>
                        </div>

                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltip}
                        >
                            <div className="background-icon" onClick={handleShowQuantityAndVariation}>
                                <FontAwesomeIcon
                                    fill='false'
                                    icon={faShoppingCart}
                                    color="rgba(255,255,255,.8)"
                                    size={'2x'}
                                />
                            </div>
                        </OverlayTrigger>
                    </Card.Body>
                </Link>

                {/* TODO: Ajustar aqui o estilo do botão, e remover de dentro do "card" */}
{/*                 

                <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltip}
                        >
                            <div className="background-icon" onClick={handleShowQuantityAndVariation}>
                                <FontAwesomeIcon
                                    fill='false'
                                    icon={faShoppingCart}
                                    color="rgba(255,255,255,.8)"
                                    size={'2x'}
                                />
                            </div>
                        </OverlayTrigger> */}


            </Card>
            <>
                <Modal show={show} onHide={handleClose}>
                    {isPhotos ?
                        <Carousel>
                            <Carousel.Item interval={null}>
                                <div className="image-carroucel-content">
                                    <img
                                        className="d-block w-100 img-modal"
                                        src={props.product.images[0]}
                                        alt="First slide"
                                    />
                                </div>
                            </Carousel.Item>
                            <Carousel.Item interval={null}>
                                <div className="image-carroucel-content">
                                    <img
                                        className="d-block w-100 img-modal"
                                        src={props.product.images[1]}
                                        alt="Second slide"
                                    />
                                </div>
                            </Carousel.Item>
                        </Carousel>
                        : <div>
                            <Modal.Header closeButton>
                                <Modal.Title>{props.product.name}</Modal.Title>
                            </Modal.Header>
                            <Form className="quantity-form-modal">
                                <Container>
                                    <Row>
                                        {itemsVariationFormNumber.map((i, k) => {
                                            return (productAddForm())
                                        })}
                                        {/* <Col xs={12} md={6} lg={4} className="display-flex">
                                            <Form.Group controlId="exampleForm.ControlSelect1">
                                                <div className="background-plus-modal-icon" onClick={() => handleAddNewVariationForm(2)}>
                                                    <div>
                                                        <FontAwesomeIcon
                                                            fill='false'
                                                            icon={faPlus}
                                                            color="red"
                                                            size={'lg'}
                                                        />
                                                    </div>
                                                </div>
                                            </Form.Group>
                                        </Col> */}
                                    </Row>
                                    <br />
                                    <Modal.Footer>
                                        <div className="align-button-rigth">
                                            <Button onClick={handleAddProductModal} variant="info">Adicionar ao Orçamento</Button>
                                        </div>
                                    </Modal.Footer>
                                    <Row className="align-alert-added-product">
                                        {isProductAdded &&
                                            <Alert variant="info" className="alert-adjusts">
                                                Produdo adicionado ao orçamento
                                            </Alert>}
                                    </Row>
                                </Container>
                            </Form>
                        </div>}
                </Modal>
            </>
        </div >
    )
}

export default Item;